import { useEffect } from "react";

import {
	useSetPopularCreatorsList,
	useSetPopularStreamingGamesList,
	useGetPopularCreatorsList,
	useGetPopularStreamingGamesList
} from "_common/hooks/global";
import { popularCreatorsFetcher }   from "_components/organisms/sideBarMenu/container";
import { getPopularStreamingGames } from "_services/api/broadcast";

import Presentation from "./presentation";

const Footer = () => {
	const setPopularStreamingGamesList = useSetPopularStreamingGamesList ();
	const setPopularCreatorsList       = useSetPopularCreatorsList ();

	const popularStreamingGamesList = useGetPopularStreamingGamesList ();
	const popularCreatorsList       = useGetPopularCreatorsList ();

	const getFooterData = async () => {
		try {

			if ( !popularStreamingGamesList.length ) {
				const gamesList = await getPopularStreamingGames ( { pageNo: 1, pageSize: 20, isDesktopGame: 0, sortByPriority: 1 } );

				setPopularStreamingGamesList ( gamesList );
			}

			if ( !popularCreatorsList.length ) {
				const popularCreators = await popularCreatorsFetcher ();

				setPopularCreatorsList ( popularCreators );
			}

		} catch ( error ) {
			// do nothing
		}

	};

	useEffect ( () => {
		getFooterData ();
	}, [] );

	return (
		<Presentation
			popularCreatorsList       = { popularCreatorsList }
			popularStreamingGamesList = { popularStreamingGamesList }
		/>
	);
};

export default Footer;
