import { Button } from "@mantine/core";
import Link       from "next/link";

import { convertToSlug } from "_common/utils";
import Icon              from "_components/atoms/icon";
import Image             from "_components/atoms/image";
import Typography        from "_components/atoms/typography";
import Chip              from "_components/molecules/chip";
import useStyles         from "_styles/organisms/footer";

const socialMediaIcons = [
	{ name: "instagramRounded", link: "https://www.instagram.com/rooter.gg/" },
	{ name: "facebookRounded", link: "https://www.facebook.com/rootersports/" },
	{ name: "linkedinRounded", link: "https://www.linkedin.com/company/hello-rooter/mycompany/?viewAsMember=true" },
	{ name: "twitterRounded", link: "https://twitter.com/RooterSports" }
];

const component = ( { popularStreamingGamesList = [], popularCreatorsList = [] } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const googleAndAppleBtnContainer = (
		<div className = { classes.buttonAndQrcodeContainer }>
			<div className = { classes.buttonContainer }>
				<a
					href   = { "https://play.google.com/store/apps/details?id=com.threesixteen.app&hl=en_IN&gl=US" }
					rel    = "noopener noreferrer"
					target = "_blank"
				>
					<Button className = { classes.button }
						leftIcon         = { (
							<Icon sameInBothTheme
								className = { classes.appStoreLogo }
								name      = "googlePlayLogo"
							>
							</Icon>
						) }
						// p = "xl"
						size             = "xl"
						variant          = "contained"
					>
						Google Play
					</Button>
				</a>

				<a
					href   = { "https://apps.apple.com/in/app/rooter-live-gaming-sports/id1115677906" }
					rel    = "noopener noreferrer"
					target = "_blank"
				>
					<Button className = { classes.button }
						leftIcon         = { (
							<Icon className = { classes.appStoreLogo }
								name           = "appleLogo"
							>
							</Icon>
						) }
						// p = "xl"
						size             = "xl"
						variant          = "contained"
					>
						Apple Store
					</Button>
				</a>
			</div>

			<Image
				className = { classes.qrcode }
				src       = "/images/qr/QR_Code.webp"
			/>

		</div>
	);

	return (
		<>
			<div className = { classes.mainContainer }>
				<div className = { classes.container }>
					<div className = { classes.box1 }>
						<Link href = "/">
							{
								<Icon className = { `${ classes.rooterLogo }` }
									name           = "rooterLogo"
								>
								</Icon>
							}

						</Link>

						<div className = { classes.titleContainer }>
							<Typography className = { classes.gameTitle }
								title                = { "India’s Largest Game" }
							/>

							<Typography className = { classes.gameTitle }
								title                = { "Streaming & Esports Platform" }
							/>
						</div>

						<Typography
							className = "bottomSpacing"
							title     = { "Download App" }
							weight    = "500"
						/>

						{googleAndAppleBtnContainer}

					</div>

					<div className = { classes.box2 }>
						<Typography className = { classes.title }
							title                = { "Company" }
						/>

						<div className = { classes.itemsContainer }>
							<a
								href   = { "https://pages.rooter.gg/" }
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<Typography className = { classes.item }
									title                = { "About Us" }
								/>
							</a>

							<a href = "https://www.linkedin.com/company/hello-rooter/jobs/?viewAsMember=true"
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<Typography className = { classes.item }
									title                = { "Work With Us" }
								/>
							</a>

							<a href = { "https://pages.rooter.gg/" }
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<Typography className = { classes.item }
									title                = { "Media" }
								/>
							</a>
						</div>
					</div>

					<div className = { classes.box3 }>
						<Typography className = { classes.title }
							title                = { "Help" }
						/>

						<div className = { classes.itemsContainer }>
							<a href = { "https://pages.rooter.gg/terms-of-service" }
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<Typography className = { classes.item }
									title                = { "Terms of Use" }
								/>
							</a>

							<a
								href   = { "https://pages.rooter.gg/privacy-policy" }
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<Typography className = { classes.item }
									title                = { "Privacy Policy" }
								/>
							</a>

						</div>
					</div>

					<div className = { classes.box4 }>
						<Typography className = { classes.title }
							title                = { "Reach us at:" }
						/>

						<div className = { classes.itemsContainer }>
							<a href = "mailto:support@rooter.io">
								<div className = { classes.itemContainer }>
									<Icon
										className = "icon"
										name      = "mailRounded"
									/>

									<Typography className = { classes.itemWithIcon }
										title                = { "support@rooter.io" }
									/>
								</div>
							</a>

							<a href = "tel:91 11-41000921">
								<div className = { classes.itemContainer }>
									<Icon
										className = "icon"
										name      = "callRounded"
									/>

									<Typography className = { classes.itemWithIcon }
										title                = { "+91 11-41000921" }
									/>
								</div>
							</a>

							<a
								href   = "https://www.google.com/maps/place/Rooter+Sports+Technologies+Pvt+Ltd/@28.5591376,77.2431668,17z/data=!3m1!4b1!4m5!3m4!1s0x390ce3cf7537cf11:0x49496a5e02159cfe!8m2!3d28.5591364!4d77.2453544?shorturl=1"
								rel    = "noopener noreferrer"
								target = "_blank"
							>
								<div className = { classes.itemContainer }>
									<Icon
										className = "icon"
										name      = "homeRounded"
									/>

									<Typography className = { classes.itemWithIcon }
										title                = { "Community Centre, 2nd Floor, 55, East of Kailash, New Delhi, Delhi 110065" }
									/>
								</div>
							</a>

						</div>

						<div className = { classes.socialMediaIconContainer }>
							{socialMediaIcons.map ( item => (
								<a
									key    = { item.name }
									href   = { item.link }
									rel    = "noopener noreferrer"
									target = "_blank"
								>
									<Icon
										className = { "icon" }
										name      = { item.name }
									/>
								</a>
							)
							)}

						</div>
					</div>

				</div>

				{/* Top Games */}
				{
					popularStreamingGamesList.length ? (
						<div>
							<Typography
								className = { classes.topGamesTitle }
								title     = "Top Games"
							/>

							<div className = { classes.topGamesContainer }>

								{popularStreamingGamesList.map ( game => {
									return (
										<Chip
											key          = { game.name }
											buttonStyles = { `${ classes.chipButton }` }
											href         = { `/game/${ game.id }/${ convertToSlug ( game.name ) }` }
											name         = { game.name }
										/>
									);
								} )}
							</div>
						</div>
					) : null
				}

				{/* Top Profiles */}
				{
					popularCreatorsList.length ? (
						<div>
							<Typography
								className = { classes.topGamesTitle }
								title     = "Top Profiles"
							/>

							<div className = { classes.topGamesContainer }>
								{
									popularCreatorsList.map ( profile => {
										return (
											<Chip
												key          = { profile.authorName }
												buttonStyles = { `${ classes.chipButton }` }
												href         = { `/profile/${ profile.id }` }
												name         = { profile.authorName }
											/>
										);
									} )
								}
							</div>
						</div>
					) : null
				}

			</div>

			<div className = { classes.rooterCopyrightContainer }>
				<Typography
					title  = "© 2023, Rooter Sports Technologies Pvt. Ltd."
					weight = "500"
				/>
			</div>
		</>
	);
};

export default component;
