import { createStyles } from "@mantine/emotion";

import { maxWidth } from "_common/utils";

export default createStyles ( ( theme, _, u ) => {

	return {
		mainContainer: {
			background: theme.other.darkMode ?
				theme.other.paletteNew.darkModeInclinedBlack2 :
				theme.other.paletteNew.secondaryBackgroundGrey,

			padding       : "5rem 4rem",
			marginTop     : "2rem",
			display       : "flex",
			flexDirection : "column",
			gap           : "5rem",

			[u.largerThan ( maxWidth )]: {
				maxWidth : maxWidth + "px",
				margin   : "auto"
			}

		},
		container: {
			display: "flex"

		},
		rooterLogo: {
			width: "12rem"
		},
		box1: {
			width: "30%"
		},
		box2: {
			width: "20%"
		},
		box3: {
			width: "20%"
		},
		box4: {
			width: "25%"
		},
		titleContainer: {
			marginTop    : "1rem",
			marginBottom : "2rem"
		},
		gameTitle: {
			fontSize   : "1.5rem",
			fontWeight : "500",
			opacity    : "0.8"
		},
		playIcon: {
			height : "2rem",
			width  : "2rem"
		},
		button: {
			height        : "100%",
			fontSize      : "1.5rem",
			padding       : "1.5rem 2.5rem",
			borderRadius  : "1rem",
			background    : theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey20 : "#fff",
			boxShadow     : "none",
			textTransform : "capitalize",
			color         : theme.other.darkMode ? "white" : "black",

			"&:hover": {
				background : theme.other.paletteNew.darkModeDarkGrey20,
				boxShadow  : "none"
			}

		},
		qrcode: {
			height       : "11rem",
			width        : "11rem",
			objectFit    : "contain",
			border       : `2px solid  ${ theme.other.darkMode ? theme.other.paletteNew.secondaryBlack : "transparent" }`,
			padding      : "3px",
			borderRadius : "1rem"
		},
		buttonContainer: {
			display        : "flex",
			justifyContent : "space-between",
			flexDirection  : "column",
			marginRight    : "3rem"
		},
		buttonAndQrcodeContainer: {
			marginTop : "1.5rem",
			display   : "flex"
		},
		title: {
			fontSize     : "2rem",
			fontWeight   : "500",
			marginBottom : "1.5rem"
		},
		itemsContainer: {

		},
		item: {
			marginBottom : "1.5rem",
			cursor       : "pointer",
			width        : "fit-content",
			opacity      : "0.8",
			"&:hover"    : {
				textDecoration: "underline"
			}
		},
		itemWithIcon: {
			marginBottom : "1.5rem",
			cursor       : "pointer",
			opacity      : "0.8",

			"&:hover": {
				textDecoration: "underline"
			}
		},

		appStoreLogo: {
			height : "2rem",
			width  : "2rem"
		},
		itemContainer: {
			display             : "grid",
			gridTemplateColumns : "4rem 1fr",
			marginBottom        : "1rem",

			"& .icon": {
				height      : "3rem",
				width       : "3rem",
				marginRight : "1rem"
			}
		},
		socialMediaIconContainer: {
			display             : "grid",
			gridTemplateColumns : "repeat(4, 1fr)",
			gridColumnGap       : "1rem",
			width               : "fit-content",
			cursor              : "pointer",

			"& .icon": {
				height : "4rem",
				width  : "4rem"

			}
		},
		rooterCopyrightContainer: {
			padding    : "2rem 0",
			textAlign  : "center",
			background : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack1 : theme.other.paletteNew.secondaryWhite,

			"& .text": {
				fontSize   : "1.5rem",
				fontWeight : "500"
			},

			[u.largerThan ( maxWidth )]: {
				maxWidth : maxWidth + "px",
				margin   : "auto"
			}
		},

		topGamesContainer: {
			display  : "flex",
			flexWrap : "wrap",
			gap      : "1rem"
		},

		topGamesTitle: {
			fontSize     : "2rem",
			fontWeight   : "500",
			marginBottom : "1.5rem"
		},

		chipButton: {
			fontWeight: "500 !important"
		// cursor: "pointer"
		}

	};
} );
